const NavbarLogo = () => {
  return (
    <>
      <svg
        width="129"
        height="36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1041 281.72"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="68.39"
            y1="3.2"
            x2="68.39"
            y2="272"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#0cc8e8" />
            <stop offset="1" stop-color="#2deeaa" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="213.83"
            y1="3.2"
            x2="213.83"
            y2="272"
            href="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-3"
            x1="68.39"
            y1="3.2"
            x2="68.39"
            y2="272"
            href="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-4"
            x1="213.83"
            y1="3.2"
            x2="213.83"
            y2="272"
            href="#linear-gradient"
          />
        </defs>
        <path
          className="cls-1 "
          style={{ fill: "url(#linear-gradient)" }}
          d="M118.17,144.93H18.62A18.62,18.62,0,0,0,0,163.54V263.1a18.62,18.62,0,0,0,18.62,18.62h99.55a18.62,18.62,0,0,0,18.62-18.62V163.54A18.62,18.62,0,0,0,118.17,144.93Z"
        />
        <path
          className="cls-2 "
          style={{ fill: "#111920" }}
          d="M101.84,235.57c-7.12,2-13.37,0-18.61-5.19-.58-.58-1-1.27-1.58-1.9a1.56,1.56,0,0,0-1.68-.59,1.43,1.43,0,0,0-1.09,1.32,1.77,1.77,0,0,0,.27,1.08,21.33,21.33,0,0,0,13.44,8.6c.44.09.57.23.57.66q0,3.25,0,6.5a1.92,1.92,0,0,1-3.25,1.57c-2.91-2.32-5.81-4.66-8.77-6.93a19,19,0,0,0-11.64-4c-5.16,0-10.31,0-15.47,0H53.4c1-.84,2-1.59,2.85-2.48a15.81,15.81,0,0,0,4.31-9.45c.5-3.93-.6-7.57-1.87-11.21a44.47,44.47,0,0,1-1.8-6.35,10.59,10.59,0,0,1,6.86-11.59c.21-.07.42-.13.62-.21a1.55,1.55,0,0,0-1-2.93,14,14,0,0,0-9.55,11,18.46,18.46,0,0,0,1,8.37c.77,2.34,1.69,4.63,2.31,7a14.16,14.16,0,0,1-1.75,11.59,11.41,11.41,0,0,1-8.52,5.23c-6.61.89-17-2.67-21.24-11.57a20.38,20.38,0,0,1,5.73-24.71,24,24,0,0,1,6.59-3.81,1.1,1.1,0,0,1,0,.37,24.5,24.5,0,0,0-1.11,9,16.11,16.11,0,0,0,3.4,9.11,1.47,1.47,0,0,0,1.67.53,1.33,1.33,0,0,0,1.07-1.25,2,2,0,0,0-.33-1.21c-2.57-3.35-3-7.19-2.58-11.21a21.41,21.41,0,0,1,5.8-12.77,30,30,0,0,1,25-8.91c3.88.47,7.11,2.12,9.12,5.66a6.71,6.71,0,0,1-.83,7.74,12.28,12.28,0,0,1-2.41,1.94c-.88.59-1.25,1.44-.8,2.23a1.63,1.63,0,0,0,2.32.53,11,11,0,0,0,5-5.41c.58.51,1.1,1,1.7,1.5A9.71,9.71,0,0,0,95,194.54a1.51,1.51,0,0,0,.8-1.95,1.55,1.55,0,0,0-1.8-.93c-.62.17-1.22.44-1.86.56A7.12,7.12,0,0,1,84,186.94a14.13,14.13,0,0,0-3.87-6.78c-.64-.57-1.33-1.09-2.06-1.69a42.53,42.53,0,0,1,12,3.5,34.35,34.35,0,0,1,15.07,12.82,20.91,20.91,0,0,1,3.21,7.74,15.43,15.43,0,0,1-.41,7.45c-1.38,4.17-4.81,6.06-9.06,5a26.22,26.22,0,0,1-8.81-4.59,25.58,25.58,0,0,0-6.64-3.88,8.35,8.35,0,0,0-10.8,4.77,8.12,8.12,0,0,0-.07,5.48,1.59,1.59,0,0,0,1.39,1.2,1.38,1.38,0,0,0,1.48-.84,2,2,0,0,0,.09-1.28,5,5,0,0,1,4.84-6.74,7.32,7.32,0,0,1,3.33.93,58.83,58.83,0,0,1,5.22,3.38,27.67,27.67,0,0,0,9,4.54,10.8,10.8,0,0,0,7.43-.4,10.67,10.67,0,0,0,5.18-5.43c.08-.2.17-.39.3-.68a16.7,16.7,0,0,1,1.85,4.38C114.9,224.21,110.08,233.32,101.84,235.57Z"
        />
        <path
          className="cls-3 "
          style={{ fill: "url(#linear-gradient-2)" }}
          d="M263.61,144.93H164.05a18.61,18.61,0,0,0-18.61,18.61V263.1a18.62,18.62,0,0,0,18.61,18.62h99.56a18.62,18.62,0,0,0,18.61-18.62V163.54A18.61,18.61,0,0,0,263.61,144.93Z"
        />
        <path
          className="cls-2 "
          style={{ fill: "#111920" }}
          d="M215.88,179.92h4.34a5.43,5.43,0,0,0,.68.13,21.82,21.82,0,0,1,16.86,9.73c5,7.17,5.66,15,3.12,23.18a31.38,31.38,0,0,1-7.39,12.4c-2.75,2.89-3.82,6.39-4.15,10.22a88.88,88.88,0,0,0,.09,14.13c0,.62.06,1.24.09,1.87h-12.9v-.87c0-4.62,0-9.24,0-13.87a10.31,10.31,0,0,0-10.6-10.6H187.1l.59-.9c1.35-2.07,1.13-3.16-.93-4.54-.62-.41-1.29-.75-1.91-1.17a12.16,12.16,0,0,1-1.61-1.22,2.28,2.28,0,0,1-.2-3.27,6.42,6.42,0,0,1,1-.92,29.07,29.07,0,0,0,3-2.54c2-2.26,2.68-5.08,3-8,.25-2.46.35-4.93.58-7.39a14.34,14.34,0,0,1,7.81-11.86c4.36-2.42,9.14-3.42,14-4.09C213.52,180.18,214.7,180.06,215.88,179.92Z"
        />
        <path
          className="cls-2 "
          style={{ fill: "#111920" }}
          d="M204,251.62c0-1.49.06-2.94,0-4.38a21.28,21.28,0,0,0-1.28-6.7c-1-2.59-2.94-3.62-5.71-3.25a45.49,45.49,0,0,1-5.07.47,4.44,4.44,0,0,1-3.19-1.23,2.58,2.58,0,0,1-.82-2.72c.18-.7.45-1.38.68-2.06a3,3,0,0,0,0-2.6c.25,0,.43,0,.61,0,5.64,0,11.29,0,16.94,0a7.38,7.38,0,0,1,7.33,5.82,7.77,7.77,0,0,1,.18,1.67q0,7.27,0,14.56c0,.13,0,.27,0,.45Z"
        />
        <path
          className="cls-4 "
          style={{ fill: "url(#linear-gradient-3)" }}
          d="M118.17,0H18.62A18.62,18.62,0,0,0,0,18.62v99.56a18.62,18.62,0,0,0,18.62,18.61h99.55a18.62,18.62,0,0,0,18.62-18.61V18.62A18.62,18.62,0,0,0,118.17,0Z"
        />
        <path
          className="cls-2 "
          style={{ fill: "#111920" }}
          d="M99.26,76.58c0-.11,0-.23,0-.35a2.46,2.46,0,1,1,4.9.18c0,5.78-2.57,11.39-7.4,16.23v0A21.52,21.52,0,0,1,82.67,98.2h-.15a2.17,2.17,0,0,1-.38,0A1.76,1.76,0,0,1,81,97.87a3.45,3.45,0,0,1-1.33-1.81,2.11,2.11,0,0,1,.25-1.61,2.46,2.46,0,0,1,1.56-1,4.21,4.21,0,0,1,1-.1h.17A17.31,17.31,0,0,0,99.25,76.79C99.25,76.72,99.26,76.65,99.26,76.58Z"
        />
        <path
          className="cls-2 "
          style={{ fill: "#111920" }}
          d="M90.23,76.23a2.47,2.47,0,1,1,4.91.22,13.24,13.24,0,0,1-3.8,8.9l0,0a13.27,13.27,0,0,1-8.83,3.79h-.18a2.42,2.42,0,0,1-2.68-2.26,2.29,2.29,0,0,1,.54-1.72,2.9,2.9,0,0,1,2.1-.93C86.89,83.94,89.87,80.94,90.23,76.23Z"
        />
        <path
          className="cls-2 "
          style={{ fill: "#111920" }}
          d="M76.61,79.43c-1,.28-2,.53-3,.83a2.13,2.13,0,0,0-.55.37c.15.17.25.4.44.51,1.28.76,2.59,1.49,3.87,2.25.89.52,1,.82.5,1.74a11.78,11.78,0,0,1-9.15,6.31c-1.15.17-1.33.3-1.33,1.45V102H43.88c0-.31,0-.63,0-.94,0-2.93-.05-5.86,0-8.8A11.45,11.45,0,0,0,42,85.53c-1.45-2.2-3-4.34-4.51-6.48a33.92,33.92,0,0,1-6.4-21.49,28.34,28.34,0,0,1,1.64-9.48A19,19,0,0,1,45.81,36a28.71,28.71,0,0,1,18.84.58c8.24,3.25,12.7,9.57,14,18.22a5.11,5.11,0,0,1,.14,1.16c-.2,2,.65,3.56,1.56,5.19,2,3.6,4,7.24,5.93,10.86,1.13,2.06.31,3.47-2,3.48-1.38,0-2.76,0-4.13,0-.82,0-1.26.32-1.24,1.15C79,79.12,79,78.76,76.61,79.43Z"
        />
        <path
          className="cls-2 "
          style={{ fill: "#111920" }}
          d="M113.1,77.52a38,38,0,0,1-.55,4.8c-1.24,6.2-4.16,11.45-9.2,16.52v0a30.38,30.38,0,0,1-20.92,8.38h-.1a2.78,2.78,0,0,1-2-.73,2.39,2.39,0,0,1-.68-1.74,2.51,2.51,0,0,1,2.7-2.43h.38a25.52,25.52,0,0,0,16.06-6,25.87,25.87,0,0,0,9.55-19.4,2.81,2.81,0,0,1,0-.29,3.39,3.39,0,0,1,0-.45,2.45,2.45,0,0,1,2.48-2.55,2.4,2.4,0,0,1,1.76.77,2.6,2.6,0,0,1,.63,2C113.15,76.77,113.12,77.14,113.1,77.52Z"
        />
        <path
          className="cls-5 "
          style={{ fill: "url(#linear-gradient-4)" }}
          d="M263.61,0H164.05a18.62,18.62,0,0,0-18.61,18.62v99.56a18.61,18.61,0,0,0,18.61,18.61h99.56a18.61,18.61,0,0,0,18.61-18.61V18.62A18.62,18.62,0,0,0,263.61,0Z"
        />
        <path
          className="cls-2 "
          style={{ fill: "#111920" }}
          d="M251.43,99.25a7.17,7.17,0,0,1-6.06,1.7c-6.08-1.32-12.1-2.87-18.15-4.32a6,6,0,0,1-4.17-3.77A54.74,54.74,0,0,1,219.13,79c-.81-5.13-1.22-10.33-1.83-15.49a3.11,3.11,0,0,0-.59-1.37l0,0a3.75,3.75,0,0,0-5.7,0c-.12.14-.24.27-.35.41a2.29,2.29,0,0,0-.27,1.28,97.08,97.08,0,0,1-4.94,27.12c0,.16-.11.31-.16.47a7.72,7.72,0,0,1-6.1,5.71c-5.59,1.23-11.14,2.68-16.73,3.87a7.31,7.31,0,0,1-6-1.53A4.09,4.09,0,0,1,174.6,96c0-10.91,1.56-21.54,5.87-31.64,2.71-6.33,6.29-12.1,11.84-16.39a14.2,14.2,0,0,1,8.28-3.39,7.52,7.52,0,0,1,6.24,3,14.11,14.11,0,0,1,3.07,7,4.65,4.65,0,0,0,.21.7l.4.11a7.06,7.06,0,0,0,.68-2c.06-5.41,0-10.81,0-16.21V35.7h5.2v1.4c0,5.45,0,10.89,0,16.34a6.58,6.58,0,0,0,.68,2l.45-.12c.08-.39.18-.79.23-1.19a13.4,13.4,0,0,1,4-7.59c2.23-2.27,5.05-2.32,7.9-1.48,4.32,1.27,7.52,4.16,10.33,7.52,5.06,6,8.11,13.1,10.15,20.63a85.59,85.59,0,0,1,2.83,22.69A4.14,4.14,0,0,1,251.43,99.25Z"
        />
        <path
          className="cls-2 dark:fill-white fill-[#111920]"
          // style={{ fill: "#111920" }}
          d="M332.54,29h46.63V223.09H332.54Z"
        />
        <path
          className="cls-2 dark:fill-white fill-[#111920]"
          // style={{ fill: "#111920" }}
          d="M528.55,185.67v37.42H403.18V190.42l65.62-77.07H406V75.94H526V110l-64.51,75.67Z"
        />
        <path
          className="cls-2 dark:fill-white fill-[#111920]"
          // style={{ fill: "#111920" }}
          d="M668.72,185.67v37.42H543.35V190.42L609,113.35H546.14V75.94H666.21V110L601.7,185.67Z"
        />
        <path
          className="cls-2 dark:fill-white fill-[#111920]"
          // style={{ fill: "#111920" }}
          d="M835.41,75.94,783.2,223.09l-17.59,50H719.82l19.26-50H723.45L676.54,75.94h48l29,110.57L787.67,75.94Z"
        />
        <path
          className="cls-2 dark:fill-white fill-[#111920]"
          // style={{ fill: "#111920" }}
          d="M938.19,172.27h-79L841.3,223.09H812L880.67,29h35.74l69,194.06H956.06ZM929.54,148,898.82,60.3l-31,87.68Z"
        />
        <rect
          className="cls-2 dark:fill-white fill-[#111920]"
          // style={{ fill: "#111920" }}
          x="1005.2"
          y="29.03"
          width="29.6"
          height="194.06"
        />
      </svg>
    </>
  );
};

export default NavbarLogo;
